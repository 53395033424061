<template>
  <section>
    <b-row class="row2">
      <b-col md="3">
        <b-card class="card-sticky">
          <div class="mb-2">
            <h4 class="text-primary">
              Cộng tác viên
            </h4>
          </div>
          <div class="mb-2 text-center">
            <b-avatar
              size="70px"
              :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
            />
          </div>
          <div class="mb-2 text-center">
            <h3 class="text-primary mb-1">
              Nguyễn Văn A
            </h3>

            <b-badge variant="light-success mb-1">
              Xếp hạng: #100
            </b-badge>

            <div class="d-flex justify-content-between">
              <h3 class="text-warning">
                10 IFS
              </h3>
              <router-link to="points-history">
                <small>Lịch sử điểm</small>
                <feather-icon
                  icon="ArrowRightIcon"
                  class="ml-25"
                />
              </router-link>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex flex-column mb-1">
              <strong>Sinh nhật</strong>
              <span>08/07/2022</span>
            </div>
            <div class="d-flex flex-column mb-1">
              <strong>CMND / CCCD</strong>
              <span>09876543211</span>
            </div>
            <div class="d-flex flex-column mb-1">
              <strong>Số tài khoản ngân hàng</strong>
              <span>4803687</span>
            </div>
            <div class="d-flex flex-column mb-1">
              <strong>Ngân hàng</strong>
              <span>ACB</span>
            </div>
            <div class="d-flex flex-column">
              <strong>Địa chỉ</strong>
              <span>352 Thống Nhất, Phường 16, Quận Gò Vấp, TP.HCM</span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-card>
          <div class="filter-tab w-100 mb-2">
            <div
              v-for="item in filterTabs"
              :key="item.id"
              :class="['filter-tab__item', {'active': filterSelected == item.id}]"
              @click="filterSelected = item.id"
            >
              {{ $t(item.label) }}
            </div>
          </div>

          <div class="history-point">
            <div
              v-for="(item, index) in 10"
              :key="index"
              class="history-point__item"
            >
              <div class="left">
                <img
                  src="https://staging.ifashion.tech/_next/image?url=%2Fimages%2Flogo.png&w=32&q=75"
                  alt=""
                  height="40"
                  width="40"
                >
                <div class="content">
                  <h4 class="text-primary mb-0">
                    Thưởng hoa hồng cho mỗi đơn hàng
                  </h4>
                  <div>IFashion tặng xu cho đơn hàng hoàn thành</div>
                  <div>20:30 27-08-2022</div>
                </div>
              </div>

              <div class="right">
                <!-- class: "text-success": plus point | "text-danger": minus point -->
                <h4 class="text-success">
                  +50
                </h4>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'MemberDetail',
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
  },
  props: {},

  data() {
    return {
      filterSelected: 'all',
      filterTabs: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 'da-nhan',
          label: 'Đã nhận',
        },
        {
          id: 'da-dung',
          label: 'Đã dùng',
        },
      ],
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import url(./member.scss)
</style>
